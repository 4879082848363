<template>
	<v-footer padless class="py-5">
		<div style="width: 100%" class="d-flex flex-wrap">
			<v-row justify="center" no-gutters>
				<v-btn text x-small rounded class="my-3" :to="{ name: 'Contact' }">
					{{ $t('nav.contactUs') }}
				</v-btn>
				<v-btn text x-small rounded class="my-3" :to="{ name: 'FAQ' }">
					{{ $t('nav.faq') }}
				</v-btn>
				<v-btn text x-small rounded class="my-3" :to="{ name: 'Team' }">
					{{ $t('nav.team') }}
				</v-btn>
				<v-btn text x-small rounded class="my-3" :to="{ name: 'Policies' }">
					{{ $t('policies.title') }}
				</v-btn>
			</v-row>
			<v-row justify="center" no-gutters>
				<v-col class="py-1 text-center" cols="3">
					<v-btn icon href="https://instagram.com/oversos.official" target="_blank">
						<v-icon>mdi-instagram</v-icon>
					</v-btn>
				</v-col>
				<v-col class="py-1 text-center" cols="3">
					<v-btn icon href="https://facebook.com/oversos" target="_blank">
						<v-icon>mdi-facebook</v-icon>
					</v-btn>
				</v-col>
				<v-col class="py-1 text-center" cols="3">
					<v-btn icon href="https://twitter.com/oversosofficial" target="_blank">
						<v-icon>mdi-twitter</v-icon>
					</v-btn>
				</v-col>
				<v-col class="py-1 text-center" cols="3">
					<v-btn icon href="https://linkedin.com/company/oversos" target="_blank">
						<v-icon>mdi-linkedin</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-row justify="center" no-gutters>
				<v-col class="py-1 text-center" cols="12"> {{ new Date().getFullYear() }} — &copy; <strong>OverSOS</strong> </v-col>
			</v-row>
		</div>
		<v-card max-width="1200" class="mt-5 mx-auto d-flex flex-wrap justify-space-around" flat tile color="transparent">
			<v-card-text class="pt-0">
				This page uses the following royalty-free illustrations:
				<br />
				<a class="text-decoration-none" href="https://iconscout.com/illustrations/online-learning-concept" target="_blank">
					Online learning concept Illustration
				</a>
				by
				<a class="text-decoration-none" href="https://iconscout.com/contributors/delesign" target="_blank">Delesign Graphics</a>
				<br />
				<a class="text-decoration-none" href="https://iconscout.com/illustrations/career" target="_blank">Career Illustration</a> on
				<a class="text-decoration-none" href="https://iconscout.com">Iconscout</a>
			</v-card-text>
		</v-card>
	</v-footer>
</template>

<script>
export default {
	name: 'LandingFooter',
}
</script>
